import componentStyles from "../css/App.module.css";

import {useEffect, useRef} from "react";
import {Col, Container, Row} from "react-bootstrap";

import homepageDesign from "../resources/home/homepage-design.svg";
import homepageCode from "../resources/home/homepage-code.svg";

export default function ImgCompareImgComponent() {
    const picSpacing = {padding: "0"};
    const codeStyle = `${componentStyles.imgCompImg} ${componentStyles.imgCompOverlay}`;

    const imgDesignRef = useRef(null);
    const imgCodeRef = useRef(null);
    const imgCodeContainerRef = useRef(null);

    useEffect(() => {
        let imgDesignWidth = imgDesignRef.current.width;

        imgCodeRef.current.width = imgDesignWidth;

        imgCodeContainerRef.current.style.width = (imgDesignWidth / 2) + "px";

        function resizeImage() {
            imgCodeRef.current.width = imgDesignRef.current.width;
        }

        function compareImages(event) {
            let currentMousePos = { x: -1 };
            let a, x;

            currentMousePos.x = event.pageX;

            a = imgCodeContainerRef.current.getBoundingClientRect();

            x = currentMousePos.x - a.left;

            if (x < 0) { x = 0; }
            if (x > imgDesignWidth) { x = imgDesignWidth; }

            imgCodeContainerRef.current.style.width = x + "px";
        }

        document.addEventListener("mousemove", compareImages);
        window.addEventListener("resize", resizeImage);

        return () => {
            document.removeEventListener("mousemove", compareImages);
            window.removeEventListener("resize", resizeImage);
        }
    }, []);

    return (
        <Container className={componentStyles.imgCompImgContainer}>
            <Row className={componentStyles.imgCompImg}>
                <Col style={picSpacing}>
                    <img ref={imgDesignRef} src={homepageDesign} width={"100%"} alt={"bobo design"} />
                </Col>
            </Row>
            <Row ref={imgCodeContainerRef} className={codeStyle}>
                <Col style={picSpacing}>
                    <img ref={imgCodeRef} src={homepageCode} width={"100%"} alt={"bobo code"} />
                </Col>
            </Row>
        </Container>
    );
}