import componentStyles from "../css/App.module.css";

import {Row, Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faHeart} from "@fortawesome/pro-solid-svg-icons";
import {faHeartBroken} from "@fortawesome/pro-solid-svg-icons";

export default function ProsConsComponent(props) {
    return (
        <Row>
            <Col md={6}>
                <div className={componentStyles.prosConsTitle}>
                    <FontAwesomeIcon icon={faHeart} />
                    <p>Likes</p>
                </div>
                <ul className={componentStyles.prosConsList}>
                    {props.pros.map((content, index) => (
                        <li key={index}>
                            {content}
                        </li>
                    ))}
                </ul>
            </Col>
            <Col md={6}>
                <div className={componentStyles.prosConsTitle}>
                    <FontAwesomeIcon icon={faHeartBroken} />
                    <p>Dislikes</p>
                </div>
                <ul className={componentStyles.prosConsList}>
                    {props.cons.map((content, index) => (
                        <li key={index}>
                            {content}
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    );
}