import componentStyles from "../css/App.module.css";

import {Container} from "react-bootstrap";

export default function DividerComponent() {
    return (
        <Container className={componentStyles.dividerContainer}>
            <div className={componentStyles.sectionDivider}></div>
        </Container>
    );
}