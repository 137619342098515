import componentStyles from "../css/App.module.css";

import {Row, Col} from "react-bootstrap";

export default function SubsectionComponent(props) {
    return (
        <Row>
            <Col>
                <h4 className={componentStyles.subsectionTitleSpace}>{props.subtitle}</h4>
                {props.children}
            </Col>
        </Row>
    );
}