import componentStyles from "../css/App.module.css";

import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import logo from "../resources/home/logo.svg";
import resume from "../resources/resume/yujia-wang-resume.pdf";

import {faEnvelope} from "@fortawesome/pro-solid-svg-icons";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

export default function SidebarComponent() {
    const navStyle = `${componentStyles.navSpace} ${componentStyles.navText}`;

    return (
        <nav className={componentStyles.sidebar}>
            <Container>
                <Row className={componentStyles.logo}>
                    <Col className={componentStyles.center}>
                        <Link to={"/"}>
                            <img src={logo} width={"48"} alt={"logo"} />
                        </Link>
                    </Col>
                </Row>
                <Row className={navStyle}>
                    <Col className={componentStyles.center}>
                        <Link to={"/"}>HOME</Link>
                    </Col>
                </Row>
                <Row className={navStyle}>
                    <Col className={componentStyles.center}>
                        <Link to={"/about"}>ABOUT</Link>
                    </Col>
                </Row>
                <Row className={navStyle}>
                    <Col className={componentStyles.center}>
                        <a href={resume} target={"_blank"} rel="noreferrer">RESUME</a>
                    </Col>
                </Row>
                <Row className={componentStyles.contactContainer}>
                    <Col md={12} className={componentStyles.contact}>
                        <a href={"mailto:yj.wang0605@gmail.com"} target={"_blank"} rel="noreferrer">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    </Col>
                    <Col md={12} className={componentStyles.contact}>
                        <a href={"https://www.linkedin.com/in/yujia-wang"} target={"_blank"} rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                    </Col>
                </Row>
            </Container>
        </nav>
    );
}