import componentStyles from "../css/App.module.css";

import {Container, Row, Col} from "react-bootstrap";
import ImgPopupComponent from "./imgPopupComponent";

import aboutMe from"../resources/about/about.svg";
import about1 from "../resources/about/about1.jpg";
import about2 from "../resources/about/about2.jpg";
import about3 from "../resources/about/about3.jpg";
import about4 from "../resources/about/about4.jpg";
import about5 from "../resources/about/about5.jpg";
import about6 from "../resources/about/about6.jpg";
import about7 from "../resources/about/about7.jpg";
import about8 from "../resources/about/about8.jpg";
import about9 from "../resources/about/about9.jpg";
import about10 from "../resources/about/about10.jpg";
import about11 from "../resources/about/about11.jpg";
import about12 from "../resources/about/about12.jpg";

export default function AboutIntroComponent() {
    const galleryTopMargin = {marginTop: "11.2rem"};

    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <img src={aboutMe} width={"100%"}  alt={"about me"}/>
                    </Col>
                </Row>
            </Container>
            <Container className={componentStyles.projectContent}>
                <Row>
                    <Col>
                        <p className={componentStyles.aboutBoldText}>Hey! I'm Bobo!</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>My name is Yujia (Bobo) Wang. Currently I'm a UX/UI designer in EverCommerce - DrChrono.</p>
                        <p>I'm a designer - I care about every user and I strive to bring enjoyable experiences into people's lives.</p>
                        <p>I'm a developer - I make ideas become a reality.</p>
                        <p>I'm a student - I am always striving to learn more and grow myself.</p>
                        <p>I'm a dream chaser - I never give up and I welcome challenges.</p>
                        <p>I'm a lover - I am proud of who I am and I believe in myself.</p>
                    </Col>
                </Row>
                <Row style={galleryTopMargin}>
                    <Col>
                        <p className={componentStyles.aboutBoldText}>I used to dream of flying in the sky. I still do...</p>
                    </Col>
                </Row>
                <Row className={componentStyles.gallery}>
                    <Col md={3}>
                        <ImgPopupComponent src={about1} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 1"} />
                    </Col>
                    <Col md={3}>
                        <ImgPopupComponent src={about2} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 2"} />
                    </Col>
                    <Col md={3}>
                        <ImgPopupComponent src={about3} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 3"} />
                    </Col>
                    <Col md={3}>
                        <ImgPopupComponent src={about4} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 4"} />
                    </Col>
                </Row>
                <Row className={componentStyles.gallery}>
                    <Col md={3}>
                        <ImgPopupComponent src={about5} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 5"} />
                    </Col>
                    <Col md={3}>
                        <ImgPopupComponent src={about6} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 6"} />
                    </Col>
                    <Col md={3}>
                        <ImgPopupComponent src={about7} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 7"} />
                    </Col>
                    <Col md={3}>
                        <ImgPopupComponent src={about8} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 8"} />
                    </Col>
                </Row>
                <Row className={componentStyles.gallery}>
                    <Col md={3}>
                        <ImgPopupComponent src={about9} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 9"} />
                    </Col>
                    <Col md={3}>
                        <ImgPopupComponent src={about10} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 10"} />
                    </Col>
                    <Col md={3}>
                        <ImgPopupComponent src={about11} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 11"} />
                    </Col>
                    <Col md={3}>
                        <ImgPopupComponent src={about12} className={componentStyles.imgRadius} width={"100%"} alt={"gallery 12"} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}