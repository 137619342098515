import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollToTopComponent from "./components/scrollToTopComponent";
import Home from "./pages/home";
import About from "./pages/about";
import Drchrono from "./pages/drchrono";
import FoodEx from "./pages/foodex";
import VStyle from "./pages/vstyle";
import FittingEasy from "./pages/fittingeasy";

function App() {
  return (
      <BrowserRouter>
        <ScrollToTopComponent>
            <Routes>
                <Route path={"/"} element={<Home />} />
                <Route path={"about"} element={<About />} />
                <Route path={"drchrono"} element={<Drchrono />} />
                <Route path={"foodex"} element={<FoodEx />} />
                <Route path={"vstyle"} element={<VStyle />} />
                <Route path={"fittingeasy"} element={<FittingEasy />} />
            </Routes>
        </ScrollToTopComponent>
      </BrowserRouter>
  );
}

export default App;
