import componentStyles from "../css/App.module.css";

import {useState} from "react";
import {motion} from "framer-motion";
import ReactModal from "react-modal";

export default function ImgPopupComponent(props) {
    const [isOpen, setIsOpen] = useState(false);
    const popImageStyle = {height: "75vh", width: "auto"};

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <motion.img
                src={props.src}
                className={props.className}
                width={props.width}
                alt={props.alt}
                onClick={handleOpenModal}
                whileHover={{cursor: "zoom-in"}}
                transition={{duration: 0.2}}
            />
            <ReactModal
                overlayClassName={componentStyles.modalOverlay}
                className={componentStyles.modalContent}
                isOpen={isOpen}
                onRequestClose={handleCloseModal}
            >
                <img src={props.src} style={popImageStyle} alt={props.alt} onClick={handleCloseModal} />
            </ReactModal>
        </div>
    );
}