import componentStyles from "../css/App.module.css";

import {Container, Row, Col} from "react-bootstrap";

export default function ProjectImgComponent(props) {
    const imgStyle = {position: "relative"};

    return (
        <div>
            <Container>
                <Row>
                    <Col style={imgStyle}>
                        <img src={props.imgSrc} width={"100%"} alt={props.altText} />
                        <div className={componentStyles.projectImgBtnWrapper} style={{display: props.showBtn? "block" : "none"}}>
                            <a href={props.projectLink} rel={"noreferrer"} target={"_blank"}>
                                <button className={componentStyles.projectImgBtn}>{props.btnText}</button>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}