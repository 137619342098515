import componentStyles from "../css/App.module.css";

import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faArrowLeftLong} from "@fortawesome/pro-light-svg-icons";
import {faEnvelope} from "@fortawesome/pro-solid-svg-icons";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";

export default function ProjectSidebarComponent(props) {
    const navStyle = `${componentStyles.navSpace} ${componentStyles.navText}`;

    return (
        <nav className={componentStyles.sidebar}>
            <Container>
                <Row className={componentStyles.back}>
                    <Col className={componentStyles.center}>
                        <Link to={"/"}>
                            <FontAwesomeIcon icon={faArrowLeftLong} />
                        </Link>
                    </Col>
                </Row>
                {props.navItem.map(({url, name}, index) => (
                    <Row className={navStyle} key={index}>
                        <Col className={componentStyles.center}>
                            <a href={url}>{name}</a>
                        </Col>
                    </Row>
                ))}
                <Row className={componentStyles.contactContainer}>
                    <Col md={12} className={componentStyles.contact}>
                        <a href={"mailto:yj.wang0605@gmail.com"} target={"_blank"} rel="noreferrer">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    </Col>
                    <Col md={12} className={componentStyles.contact}>
                        <a href={"https://www.linkedin.com/in/yujia-wang"} target={"_blank"} rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </Col>
                </Row>
            </Container>
        </nav>
    );
}