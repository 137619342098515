import componentStyles from "../css/App.module.css";

import {Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import ProjectWidgetComponent from "./projectWidgetComponent";
import FooterComponent from "./footerComponent";

import foodex from "../resources/home/foodex.png";
import vstyle from "../resources/home/vstyle.png";
import fittingeasy from "../resources/home/fittingeasy.png";

export default function ProjectListComponent() {
    return (
        <Stack className={componentStyles.projectList}>
            <div className={componentStyles.titleContainer}>
                <h2>FEATURED PROJECTS</h2>
            </div>
            <Stack direction={"horizontal"} className={componentStyles.horizontalProject}>
                <Link to={"/drchrono"}>
                    <ProjectWidgetComponent imgSrc={fittingeasy} altText={"DrChrono Design System"} />
                </Link>
                <Link to={"/foodex"}>
                    <ProjectWidgetComponent imgSrc={foodex} altText={"FoodEx"} />
                </Link>
                <Link to={"/vstyle"}>
                    <ProjectWidgetComponent imgSrc={vstyle} altText={"VStyle"} />
                </Link>
                <Link to={"/fittingeasy"}>
                    <ProjectWidgetComponent imgSrc={fittingeasy} altText={"FittingEasy"} />
                </Link>
            </Stack>
            <div>
                <FooterComponent />
            </div>
        </Stack>
    );
}