import SidebarComponent from "../components/sidebarComponent";
import HomepageComponent from "../components/homepageComponent";
function Home() {
    return (
        <div>
            <SidebarComponent />
            <HomepageComponent />
        </div>
    );
}

export default Home;