import componentStyles from "../css/App.module.css";

function DrChrono() {
    return (
        <div>

        </div>
    );
}

export default DrChrono;