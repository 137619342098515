import funStyles from "../css/vstyle.module.css";

import {Row, Col} from "react-bootstrap";
import VstyleFunFilterComponent from "./vstyleFunFilterComponent";

export default function VstyleFunFilterGroupComponent(props) {
    const filterContainerStyle = {paddingTop: "1.6rem", paddingBottom: "1.6rem"};

    let position = {
        justifyContent: "",
        textAlign: ""
    };

    if (props.align === "right") {
        position.justifyContent = "flex-end";
        position.textAlign = "right";
    } else {
        position.justifyContent = "flex-start";
        position.textAlign = "left";
    }

    const onAccessoryChange = (index) => {
        const temp = [...props.activeFilter];
        temp[index] = !temp[index];
        props.setActiveFilter(temp);
    };

    const onChangeFilter = (index) => {
        props.setActiveFilter(index);
    };

    const filters = props.filters.map(({type, src, color}, index) => {
        if (props.filterName === "Accessory") {
            return <VstyleFunFilterComponent type={type} src={src} color={color} key={index} isActive={props.activeFilter[index]} onClick={() => onAccessoryChange(index)} />
        } else {
            return <VstyleFunFilterComponent type={type} src={src} color={color} key={index} isActive={(props.activeFilter === index)} onClick={() => onChangeFilter(index)}/>;
        }
    });

    return (
        <Row style={filterContainerStyle}>
            <Col
                md={12}
                className={funStyles.filterTitleContainer}
                style={{justifyContent: position.justifyContent}}
            >
                <p>{props.filterName}</p>
            </Col>
            <Col
                md={12}
                className={funStyles.filterItemContainer}
                style={{justifyContent: position.justifyContent}}
            >
                {filters}
            </Col>
        </Row>
    );
}