import componentStyles from "../css/App.module.css";

import {Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowLeft} from "@fortawesome/pro-regular-svg-icons";
import {faLongArrowRight} from "@fortawesome/pro-regular-svg-icons";

export default function NextPreviousComponent(props) {
    return (
        <Row>
            <Col md={6}>
                <div className={componentStyles.previousPageLink}>
                    <Link to={props.previousLink}>
                        <FontAwesomeIcon icon={faLongArrowLeft} />
                        {props.previousText}
                    </Link>
                </div>
            </Col>
            <Col md={6}>
                <div className={componentStyles.nextPageLink}>
                    <Link to={props.nextLink}>
                        {props.nextText}
                        <FontAwesomeIcon icon={faLongArrowRight} />
                    </Link>
                </div>
            </Col>
        </Row>
    );
}