import componentStyles from "../css/App.module.css";

export default function ProblemBlockComponent(props) {
    return (
        <div className={componentStyles.problemBlock}>
            <p className={componentStyles.problemText1}>{props.title}</p>
            {props.text.map((content, index) => (
                <h4 className={componentStyles.problemText2} key={index}>
                    {content}
                </h4>
            ))}
        </div>
    );
}