import componentStyles from "../css/App.module.css";

import {Container, Row, Col} from "react-bootstrap";

export default function ProjectSectionComponent(props) {
    const contentStyle = {display:"flex", flexDirection: "column", gap:"12rem"};

    return (
        <Container className={componentStyles.projectContent} id={props.id}>
            <Row>
                <Col md={{span: 4, offset: 4}} className={componentStyles.titleSpace}>
                    <h1>{props.title}</h1>
                </Col>
            </Row>
            <div style={contentStyle}>
                {props.children}
            </div>
        </Container>
    );
}