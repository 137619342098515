import funStyles from "../css/vstyle.module.css";

export default function VstyleFunFilterComponent(props) {
    const inactiveStyle = `${funStyles.filterContainer}`;
    const activeStyle = `${funStyles.filterContainer} + ${funStyles.filterActive}`;

    if (props.type === "icon") {
        return (
            <div
                className={props.isActive ? activeStyle : inactiveStyle}
                style={{backgroundColor: props.color}}
                onClick={props.onClick}
            >
                <img src={props.src} className={funStyles.filterIcon} width={"60%"} alt={"fun icon"} />
            </div>
        )
    } else if (props.type === "fill") {
        return (
            <div
                className={props.isActive ? activeStyle : inactiveStyle}
                style={{backgroundColor: props.color}}
                onClick={props.onClick}
            />
        )
    } else {
        return (
            <div
                className={props.isActive ? activeStyle : inactiveStyle}
                style={{backgroundImage: props.color}}
                onClick={props.onClick}
            />
        )
    }
}