import componentStyles from "../css/App.module.css";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImgCompareImgComponent from "./imgCompareImgComponent";
import ProjectListComponent from "./projectListComponent";

import {faAngleDoubleDown} from "@fortawesome/pro-light-svg-icons";

export default function HomepageComponent() {
    return (
        <div className={componentStyles.homepageContainer}>
            <section className={componentStyles.intro}>
                <ImgCompareImgComponent />
                <div className={componentStyles.jobTitle}>
                    <span>UX/UI DESIGNER & ENGINEER</span>
                </div>
                <div className={componentStyles.nextPage}>
                    <FontAwesomeIcon icon={faAngleDoubleDown} fontSize={"2.4rem"} />
                </div>
            </section>
            <section>
                <ProjectListComponent />
            </section>
        </div>
    );
}